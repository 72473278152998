import {createRoot} from 'react-dom/client';
import './custom.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AppProviders from './context/app-providers';

const root = createRoot(document.getElementById('root'))
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
